
































import { Vue, Component } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Recording from '@/store/modules/recording'
import Translations from '@/store/modules/translations'
import RecordingPart from '@/store/modules/recordingPart'

import Store from '@/store/index'

import { RECORDING_STATE, COUNTDOWN_TOTAL } from '@/constants'

const recording = getModule(Recording, Store) // Accessor for recordings state.
const translations = getModule(Translations) // Accesssor for translations state.
const recordingPart = getModule(RecordingPart, Store)

const PROMPT_KEY = 'recordingsSpeechInstructionsText'

@Component
export default class RecordHello extends Vue {
  /**
   * This component is used to record each wakeword.
   * It needs to:
   * - Record the wakeword.
   * - Populate 'currentRecording'(recordings.ts) with the recorded audio.
   * Note: the timer is displayed on the UI, it indicates how long the user has left to speak the wakeword into the microphone.
   */
  currentCountdownNumber: number = COUNTDOWN_TOTAL
  promptText = ''

  handleCountdownFinished () {
    console.log('handleCountdownFinished')

    // Update state now that the recording is done
    recording.updateStatus(RECORDING_STATE.PLAYBACK)

    this.$emit('on-finished-recording-part')
  }

  mounted () {
    this.promptText = translations.getTranslations(PROMPT_KEY)
  }
}
