




































































import { Component, Vue } from 'vue-property-decorator'

import ProgressList from '@/components/shared/ProgressList.vue'
import InitiateRecording from '@/components/recordings/InitiateRecording.vue'
import RecordWakewords from '@/components/recordings/RecordWakewords.vue'
import AudioPrompt from '@/components/recordings/AudioPrompt.vue'

import Recording from '@/store/modules/recording'
import Transcripts from '@/store/modules/transcripts'
import User from '@/store/modules/user'
import RecordingPart from '@/store/modules/recordingPart'
import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

import { TRANSCRIPTS_AGE, AGE_OPTION_KIDS_VALUE, TRANSCRIPTS_TYPE, RECORDING_STATE } from '@/constants'

const translations = getModule(Translations)
const user = getModule(User, Store)
const recording = getModule(Recording, Store)
const recordingPart = getModule(RecordingPart, Store)

const transcripts = getModule(Transcripts)

const TITLE_KEY = 'recordingsTitleText'
const PROGRESS_LABEL_KEY = 'recordingsProgressText'

@Component({
  components: {
    ProgressList,
    InitiateRecording,
    RecordWakewords,
    AudioPrompt
  }
})
export default class RecordingsView extends Vue {
  /**
   * This is the Recordings view.
   * If beginRecordingState = false
   * - initiateRecording is rendered which asks the user to 'Begin' the process.
   * If beginRecordingState = true and numberOfRecordedWakewords < 16
   * - It renders RecordWakewords which handles the recording of all 16 wakewords.
   * If beginRecordingState = true and numberOfRecordedWakewords = 16
   * - it renders the complete page.
   * Note: the complete page could be removed so that the User can move directly onto the done page.
   * Note: that if quietTimeout state = true
   * - the AudioPrompt page is rendered instead of all of the above.
   */
  /*
    Step 0: Initialisation of the page
    Step 1: Do recordings
    Step 2: All recordings done
  */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  showReconnectModal = false
  webSocketMaxAttemptDone = false
  currentRecordingPartIndexFinished = 0
  titleText = translations.getTranslations(TITLE_KEY)
  progressLabel = translations.getTranslations(PROGRESS_LABEL_KEY)

  get status () {
    return recording.getStatus
  }

  get totaWWPhrases () {
    const ageGroup: string = user.getAgeGroup === AGE_OPTION_KIDS_VALUE ? TRANSCRIPTS_AGE.KIDS : TRANSCRIPTS_AGE.DEFAULT
    return transcripts.getTotalTranscriptType(ageGroup, TRANSCRIPTS_TYPE.WW) + transcripts.getTotalTranscriptType(ageGroup, TRANSCRIPTS_TYPE.PHRASE)
  }

  get quietTimeoutState () {
    return recording.getQuietTimeout
  }

  mounted () {
    // If we have on an Id of 0, it means, we haven't reloaded the page during a session
    // We are using that, as the incrementIndex is async and therefore won't be instantaenous.
    // If we don't, the line getting the transcription will use an index of potentially -1
    let recordingPartIndex: number = recordingPart.getIndex

    if (recordingPartIndex === 0) {
      recordingPart.incrementIndex()

      recordingPartIndex += 1
    }

    if (recording.getStatus === RECORDING_STATE.PLAYBACK ||
      recording.getStatus === RECORDING_STATE.COUNTING_DOWN ||
      recording.getStatus === RECORDING_STATE.RECORDING) {
      recording.updateStatus(RECORDING_STATE.IDLE)
    }

    this.currentRecordingPartIndexFinished = recordingPartIndex - 1

    // Set the transcription and type to the correct one
    const transcription: Record<string, string> = transcripts.getTranscription(recordingPartIndex - 1)

    recordingPart.updateType(transcription.type)
    recordingPart.updateTranscript(transcription.transcript)

    console.log({ totaWWPhrases: this.totaWWPhrases, currentRecordingPartIndexFinished: this.currentRecordingPartIndexFinished })
  }
}

