export default class ApiException {
  public toastTitle: string | null;
  public toastColor: string;
  public toastMessage: string;

  /**
   * The Singleton's constructor should always be private to prevent direct
   * construction calls with the `new` operator.
   */
  public constructor (toastTitle: string | null, toastColor: string, toastMessage: string) {
    this.toastTitle = toastTitle
    this.toastColor = toastColor
    this.toastMessage = toastMessage
  }
}
