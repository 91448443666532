export default class EventManager {
    private static instance: EventManager

    private eventListeners: Record<string, Array<Function>>

    /**
     * The Singleton's constructor should always be private to prevent direct
     * construction calls with the `new` operator.
     */
    private constructor () {
      console.log('Instanciated EventManager')

      this.eventListeners = {}
    }

    /**
     * The static method that controls the access to the singleton instance.
     *
     * This implementation let you subclass the Singleton class while keeping
     * just one instance of each subclass around.
     */
    public static getInstance (): EventManager {
      if (!EventManager.instance) {
        EventManager.instance = new EventManager()
      }

      return EventManager.instance
    }

    public addListener (event: string, callback: Function): void {
      // Create the event if not exists
      if (this.eventListeners[event] === undefined) {
        this.eventListeners[event] = []
      }

      this.eventListeners[event].push(callback)
    }

    public removeListener (event: string, callback: Function): void {
      // Check if this event not exists
      if (this.eventListeners[event] === undefined) {
        console.error(`This event: ${event} does not exist`)
      }

      this.eventListeners[event] = this.eventListeners[event].filter((func: Function) => func.toString() !== callback.toString())
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public dispatch (event: string, options: any) {
      // Check if this event not exists
      if (this.eventListeners[event] === undefined) {
        console.error(`This event: ${event} does not exist`)
      }

      this.eventListeners[event].forEach((listener: Function) => {
        listener(options)
      })
    }
}
