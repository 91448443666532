
import AxiosConfig from './axiosConfig'

import Configuration from '@/store/modules/configuration'
import { getModule } from 'vuex-module-decorators'

import { SERVICES, ENDPOINTS, COLOR_VARIANT, UI_TEXT_TOAST_ERROR_UNAUTHORIZED, STATUS_CODE_UNAUTHORIZED } from '../constants'

import ApiException from '@/exceptions/ApiException'

export default class Api {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public static async createUser (params: Record<string, string>): Promise<Record<string, string>> {
    const configuration = getModule(Configuration)

    const url = configuration.endpoint(SERVICES.MAIN, ENDPOINTS.USER)
    try {
      const response = await AxiosConfig.getInstance().post(SERVICES.MAIN, url, params)

      return response.data
    } catch (error) {
      console.error(error)

      if (error.response && error.response.status === STATUS_CODE_UNAUTHORIZED) {
        throw new ApiException(null, COLOR_VARIANT.WARNING, UI_TEXT_TOAST_ERROR_UNAUTHORIZED)
      } else {
        throw Error
      }
    }
  }

  public static async createRecordMetadata (params: Record<string, string|number|boolean>): Promise<Record<string, string>> {
    try {
      const url = getModule(Configuration).endpoint(SERVICES.MAIN, ENDPOINTS.RECORDING_METADATA)

      const response = await AxiosConfig.getInstance().post(SERVICES.MAIN, url, params)

      return response.data
    } catch (error) {
      console.error(error)

      if (error.response && error.response.status === STATUS_CODE_UNAUTHORIZED) {
        throw new ApiException(null, COLOR_VARIANT.WARNING, UI_TEXT_TOAST_ERROR_UNAUTHORIZED)
      } else {
        throw Error
      }
    }
  }
}
