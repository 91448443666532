/* eslint-disable @typescript-eslint/no-this-alias */
// taken from https://kazuki.github.io/opus.js-sample/index.html sources

// Safari and old versions of Chrome use webkit
import EventManager from '../EventManager'

import { EVENTS } from '../../../constants'

import { getModule } from 'vuex-module-decorators'
import Recording from '@/store/modules/recording'
import RecordingPart from '@/store/modules/recordingPart'

import Store from '@/store/index'

const recording = getModule(Recording, Store) // Accessor for recordings state.
const recordingPart = getModule(RecordingPart, Store)

const AudioContext = window.AudioContext || window.webkitAudioContext

const PERIOD_SIZE = 1024
const DELAY_PERIOD_COUNT = 4
const RING_BUFFER_PERIOD_COUNT = DELAY_PERIOD_COUNT * 4

const ENCODER_APPLICATION_SIZE = 2049
const ENCODER_SAMPLING_RATE_HRZ = 48000
const ENCODER_FRAME_DURATION = 20

const RingBuffer = (function () {
  function RingBuffer (buffer) {
    this.writePosition = 0 // write position
    this.readPosition = 0 // read position
    this.remainingDataToWrite = null
    this.buf = buffer
  }

  RingBuffer.prototype.append = function (data) {
    // console.log('append')
    const _this = this

    return new Promise(function (resolve, reject) {
      // 書き込み処理が実施中の場合は常にrejectする
      if (_this.remainingDataToWrite) {
        // eslint-disable-next-line prefer-promise-reject-errors
        // reject(new Error())
        return
      }
      const size = _this.appendSome(data)
      if (size === data.length) {
        resolve()
        return
      }
      // 空き容量がないので，読み込み処理が実施時に書き込むようにする
      // Since there is no free space, write it when the read process is executed.
      _this.remainingDataToWrite = [data.subarray(size), resolve]
    })
  }

  RingBuffer.prototype.readData = function (output) {
    // console.log('readData')

    let ret = this.readSome(output)

    if (this.remainingDataToWrite) {
      this.appendRemainingData()
      if (ret < output.length) {
        ret += this.readSome(output.subarray(ret))
      }
    }
    return ret
  }

  RingBuffer.prototype.appendSome = function (data) {
    // console.log('appendSome')
    const totalSize = Math.min(data.length, this.available())
    if (totalSize === 0) {
      return 0
    }
    // 書き込み位置からバッファの終端まで書き込む
    const pos = this.writePosition % this.buf.length
    const size = Math.min(totalSize, this.buf.length - pos)
    this.buf.set(data.subarray(0, size), pos)
    // バッファの終端に達したが，書き込むデータがまだあるため
    // バッファの先頭から書き込みを継続する
    if (size < totalSize) {
      this.buf.set(data.subarray(size, totalSize), 0)
    }
    this.writePosition += totalSize
    return totalSize
  }

  RingBuffer.prototype.appendRemainingData = function () {
    // console.log('appendRemainingData')
    const data = this.remainingDataToWrite[0]
    const resolve = this.remainingDataToWrite[1]
    this.remainingDataToWrite = null
    const size = this.appendSome(data)
    if (size === data.length) {
      resolve()
    } else {
      this.remainingDataToWrite = [data.subarray(size), resolve]
    }
  }

  RingBuffer.prototype.readSome = function (output) {
    // console.log('readSome')
    const totalSize = Math.min(output.length, this.size())
    if (totalSize === 0) {
      return 0
    }
    // 読み込み位置からバッファ終端方向に読み込む
    // Read from the read position toward the end of the buffer
    const pos = this.readPosition % this.buf.length
    const size = Math.min(totalSize, this.buf.length - pos)
    output.set(this.buf.subarray(pos, pos + size), 0)
    // バッファの終端に達したが読み込むデータがまだあるため
    // バッファの先頭から読み込みを継続する
    // because the end of the buffer has been reached but there is still data to read
    // continue reading from the beginning of the buffer
    if (size < totalSize) {
      output.set(this.buf.subarray(0, totalSize - size), size)
    }
    this.readPosition += totalSize

    return totalSize
  }

  RingBuffer.prototype.clear = function () {
    console.log('clear')
    this.readPosition = this.writePosition = 0
    this.remainingDataToWrite = null
  }

  RingBuffer.prototype.capacity = function () {
    // console.log('capacity')
    return this.buf.length
  }

  // Let's say we have a buffer of size 500
  // If we have written already 200 data, and we haven't started reading
  // It means that we still need the full 200 first data in the buffer, hence the actualy size of data unconsumed is 200
  // But if we had already read 50, then we would only need have 150 worth of data unconsumed, therefore this function woudl return 150
  RingBuffer.prototype.size = function () {
    // console.log('size')
    return this.writePosition - this.readPosition
  }

  // If the initial buffer length is 1000
  // If we have already written 512 of data, but read 256, then it means we have 1000 (total capacity) - (512 (written data) - 256 (already consumed data))
  // Giving 744 data avaiable in the buffer
  RingBuffer.prototype.available = function () {
    // console.log('available')
    return this.capacity() - this.size()
  }

  RingBuffer.MAX_POS = (1 << 16)
  return RingBuffer
})()

// **********************
// Class to read data from microphone
// **********************
const MicrophoneReader = (function () {
  const MICROPHONE_READ_TIMEOUT = 10

  function MicrophoneReader () {
    this.instant = 0.0
    this.isBeingUsed = false
  }

  MicrophoneReader.prototype.open = function (bufferSamplePerChannel) {
    const _this = this

    return new Promise(function (resolve, reject) {
      const callback = async function (stream) {
        _this.stream = stream
        // this is set inside the callback since we use audioContext.currentTime for the timestamps and if they delay in allowing mic access the timings will be off
        _this.audioContext = new AudioContext()

        // Set the audio context
        await recording.updateAudioContext(_this.audioContext)

        // Need to make the stream available to be accessed, so we can create a MediaStreamRecording object in the UI Component, in order to have the data
        // coming from the microphone, and then make it available to the user for listening again, and stream them to the backend
        EventManager.getInstance().dispatch(EVENTS.STREAM_AVAILABLE, stream)

        _this.audioSourceNode = _this.audioContext.createMediaStreamSource(stream)
        _this.ringbuf = new RingBuffer(new Float32Array(bufferSamplePerChannel * _this.audioSourceNode.channelCount * 8))
        _this.scriptProcessorNode = _this.audioContext.createScriptProcessor(0, 1, _this.audioSourceNode.channelCount)
        _this.scriptProcessorNode.onaudioprocess = function (ev) {
          _this._onaudioprocess(ev)
        }

        _this.audioSourceNode.connect(_this.scriptProcessorNode)

        _this.scriptProcessorNode.connect(_this.audioContext.destination)

        _this.bufferDataSampleChannelsTotalSize = bufferSamplePerChannel * _this.audioSourceNode.channelCount
        console.log('MicrophoneReader open callback', _this.audioContext)
        resolve({
          samplingRate: _this.audioContext.sampleRate / 2,
          numberOfChannels: _this.audioSourceNode.channelCount
        })
      }

      const errcallback = function () {
        alert("We can't access your mic?")
        return reject
      }

      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: false
      }).then(callback, errcallback)
    })
  }

  MicrophoneReader.prototype.getSoundLevel = function () {
    return this.instant
  }

  MicrophoneReader.prototype._onaudioprocess = function (ev) {
    // Append the new audio data to the ring buffer
    const numberOfChannels = ev.inputBuffer.numberOfChannels
    const channelDataSize = ev.inputBuffer.getChannelData(0).length

    const audioData = new Float32Array(numberOfChannels * channelDataSize)

    for (let indexChannel = 0; indexChannel < numberOfChannels; ++indexChannel) {
      const channelData = ev.inputBuffer.getChannelData(indexChannel)

      for (let indexChannelData = 0; indexChannelData < channelDataSize; ++indexChannelData) {
        audioData[indexChannelData * numberOfChannels + indexChannel] = channelData[indexChannelData]
      }
    }
    this.ringbuf.append(audioData)

    // Calculate the volume of the sound coming in the microphone
    const input = ev.inputBuffer.getChannelData(0)
    let sum = 0.0

    input.forEach(data => {
      sum += data * data
    })

    this.instant = Math.sqrt(sum / input.length)
  }

  MicrophoneReader.prototype.read = function () {
    const _this = this

    this.isBeingUsed = true

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    return new Promise(function (resolve, reject) {
      const buf = new Float32Array(_this.bufferDataSampleChannelsTotalSize)
      const func = function () {
        const size = _this.ringbuf.readData(buf)
        if (size === 0) {
          window.setTimeout(function () {
            func()
          }, MICROPHONE_READ_TIMEOUT)
          return
        }
        _this.isBeingUsed = false
        resolve({
          timestamp: 0,
          samples: buf.subarray(0, size),
          transferable: true
        })
      }
      func()
    })
  }

  MicrophoneReader.prototype.close = function () {
    console.log('MicrophoneReader CLOSE')

    this.stream.getTracks().forEach(function (track) {
      track.stop()
    })

    if (this.audioContext) {
      this.audioContext.close()
      this.audioContext = null

      recording.updateAudioContext(null)
    }
  }
  return MicrophoneReader
})()

const WebAudioPlayer = (function () {
  function WebAudioPlayer () {
    this.isWriting = false
    this.buffering = true
    this.handleNeedBuffer = null
    this.requestingCheckBuffer = false
  }

  WebAudioPlayer.prototype.init = function (samplingRate, numberOfChannels) {
    const _this = this
    return new Promise(function (resolve, reject) {
      _this.context = new AudioContext()
      _this.scriptProcessorNode = _this.context.createScriptProcessor(PERIOD_SIZE, 0, numberOfChannels)
      _this.gainNode = _this.context.createGain()

      _this.scriptProcessorNode.onaudioprocess = function (ev) {
        // console.log('WebAudioPlayer onaudioprocess')
        _this._onaudioprocess(ev)
      }

      if (samplingRate !== _this.getActualSamplingRate()) {
        console.log('enable resampling: ' + samplingRate + ' -> ' + _this.getActualSamplingRate())
        _this.periodSamples = Math.ceil(PERIOD_SIZE * _this.getActualSamplingRate() / samplingRate) * numberOfChannels
        _this.resampler = new Worker('/libs/resampler.js')
      } else {
        _this.periodSamples = PERIOD_SIZE * numberOfChannels
      }

      _this.ringbuf = new RingBuffer(new Float32Array(_this.periodSamples * RING_BUFFER_PERIOD_COUNT))
      _this.delaySamples = _this.periodSamples * DELAY_PERIOD_COUNT
      if (_this.resampler) {
        _this.resampler.onmessage = function (ev) {
          console.log('WebAudioPlayer init sample on message event', ev)
          if (ev.data.status === 0) {
            resolve()
          } else {
            reject(ev.data)
          }
        }

        _this.resampler.postMessage({
          channels: numberOfChannels,
          // eslint-disable-next-line @typescript-eslint/camelcase
          in_sampling_rate: samplingRate,
          // eslint-disable-next-line @typescript-eslint/camelcase
          out_sampling_rate: _this.getActualSamplingRate()
        })
      } else {
        resolve()
      }
    })
  }

  WebAudioPlayer.prototype.enqueue = function (buf) {
    // console.log('WebAudioPlayer enqueue')

    const _this = this
    return new Promise(function (resolve, reject) {
      if (_this.isWriting) {
        // eslint-disable-next-line prefer-promise-reject-errors
        reject()
        return
      }
      _this.isWriting = true
      const func = function (data) {
        _this.ringbuf.append(data).then(function () {
          _this.isWriting = false
          _this.checkBuffer()
        }, function (e) {
          _this.isWriting = false
          reject(e)
        })
      }
      if (_this.resampler) {
        const transferList = buf.transferable ? [buf.samples.buffer] : []
        _this.resampler.onmessage = function (ev) {
          if (ev.data.status !== 0) {
            _this.isWriting = false
            reject(ev.data)
            return
          }
          func(ev.data.result)
        }
        _this.resampler.postMessage({
          samples: buf.samples
        }, transferList)
      } else {
        func(buf.samples)
      }
    })
  }

  WebAudioPlayer.prototype._onaudioprocess = function (ev) {
    // console.log('WebAudioPlayer _onaudioprocess')

    this.gainNode.gain.setValueAtTime(0, this.context.currentTime)

    if (this.buffering) {
      this.checkBuffer()
    } else {
      const outputNumberOfChannels = ev.outputBuffer.numberOfChannels
      const buffer = new Float32Array(ev.outputBuffer.getChannelData(0).length * outputNumberOfChannels)
      const size = this.ringbuf.readData(buffer) / outputNumberOfChannels

      for (let indexOutputChannel = 0; indexOutputChannel < outputNumberOfChannels; ++indexOutputChannel) {
        const channelData = ev.outputBuffer.getChannelData(indexOutputChannel)
        for (let indexDataRead = 0; indexDataRead < size; ++indexDataRead) {
          channelData[indexDataRead] = buffer[indexDataRead * outputNumberOfChannels + indexOutputChannel]
        }
      }
      this.checkBuffer(true)
    }
  }

  WebAudioPlayer.prototype.checkBuffer = function (useTimeOut = false) {
    const _this = this

    // console.log('WebAudioPlayer checkBuffer')

    if (!(this.requestingCheckBuffer || !this.handleNeedBuffer)) {
      const isNeedingExtraBuffer = this.checkAgainstRingBuffer()
      if (!isNeedingExtraBuffer) {
        return
      }
      if (useTimeOut) {
        this.requestingCheckBuffer = true
        window.setTimeout(function () {
          _this.requestingCheckBuffer = false
          if (_this.checkAgainstRingBuffer()) {
            _this.handleNeedBuffer()
          }
        }, 0)
      } else {
        this.handleNeedBuffer()
      }
    }
  }

  WebAudioPlayer.prototype.checkAgainstRingBuffer = function () {
    if (this.isWriting) {
      return false
    }

    const ringBufferAvailability = this.ringbuf.available()
    const ringBufferSize = this.ringbuf.size()

    if (ringBufferSize >= this.delaySamples) {
      this.buffering = false
    }

    if (this.periodSamples <= ringBufferAvailability) {
      return true
    }

    return false
  }

  WebAudioPlayer.prototype.start = function () {
    console.log('WebAudioPlayer START')

    if (this.scriptProcessorNode) {
      console.log('WebAudioPlayerstart scriptProcessorNode valid')

      this.scriptProcessorNode.connect(this.gainNode)
      this.gainNode.connect(this.context.destination)
    }
  }

  WebAudioPlayer.prototype.stop = function () {
    console.log('WebAudioPlayer STOP')

    if (this.scriptProcessorNode) {
      this.ringbuf.clear()
      this.buffering = true
      this.scriptProcessorNode.disconnect()
    }
  }

  WebAudioPlayer.prototype.close = function () {
    console.log('WebAudioPlayer CLOSE')

    this.stop()

    if (this.context) {
      this.context.close()
    }

    this.scriptProcessorNode = null
    this.handleNeedBuffer = null
    this.context = null
  }

  WebAudioPlayer.prototype.getActualSamplingRate = function () {
    return this.context.sampleRate
  }

  WebAudioPlayer.prototype.getBufferStatus = function () {
    return {
      delay: this.ringbuf.size(),
      available: this.ringbuf.available(),
      capacity: this.ringbuf.capacity()
    }
  }
  return WebAudioPlayer
})()

const AudioEncoder = (function () {
  function AudioEncoder (path) {
    this.worker = new Worker(path)
  }

  AudioEncoder.prototype.setup = function (configuration) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this

    return new Promise(function (resolve, reject) {
      _this.worker.onmessage = function (event) {
        if (event.data.status !== 0) {
          reject(event.data)
          return
        }
        resolve(event.data.packets)
      }
      _this.worker.postMessage(configuration)
    })
  }

  AudioEncoder.prototype.encode = function (data) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this

    return new Promise(function (resolve, reject) {
      _this.worker.onmessage = function (event) {
        if (event.data.status !== 0) {
          reject(event.data)
          return
        }
        resolve(event.data.packets)
      }
      _this.worker.postMessage(data)
    })
  }
  return AudioEncoder
})()

const AudioDecoder = (function () {
  function AudioDecoder (path) {
    this.worker = new Worker(path)
  }

  AudioDecoder.prototype.setup = function (cfg, packets) {
    const _this = this
    const transferList = []
    for (let i = 0; i < packets.length; ++i) {
      transferList.push(packets[i].data)
    }

    return new Promise(function (resolve, reject) {
      _this.worker.onmessage = function (ev) {
        if (ev.data.status !== 0) {
          reject(ev.data)
          return
        }
        resolve(ev.data)
      }
      _this.worker.postMessage({
        config: cfg,
        packets: packets
      }, transferList)
    })
  }

  AudioDecoder.prototype.decode = function (packet) {
    const _this = this
    return new Promise(function (resolve, reject) {
      _this.worker.onmessage = function (ev) {
        if (ev.data.status !== 0) {
          reject(ev.data)
          return
        }
        resolve(ev.data)
      }
      _this.worker.postMessage(packet, [packet.data])
    })
  }
  return AudioDecoder
})()

const AudioEncoderDecoder = (function () {
  function AudioEncoderDecoder () {
    this.player = null
    this.microphoneAudioReader = null
    this.bufferStatus = 0
  }

  AudioEncoderDecoder.prototype.getSoundLevel = function () {
    return (Math.min(this.microphoneAudioReader.getSoundLevel().toFixed(2) * 30, 2))
  }

  AudioEncoderDecoder.prototype.start = function () {
    console.log('AudioEncoderDecoder start')
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this

    // Initialise the player
    if (this.player) {
      console.log('AudioEncoderDecoder start player exists')
      this.player.close()
    }
    this.player = new WebAudioPlayer()

    // Initialise the microphone
    this.microphoneAudioReader = new MicrophoneReader()

    if (!this.microphoneAudioReader) {
      return
    }

    let working = false
    const packetQueue = []

    const encoder = new AudioEncoder('/libs/opus_encoder.js')
    const decoder = new AudioDecoder('/libs/opus_decoder.js')

    this.microphoneAudioReader.open(PERIOD_SIZE).then(function (data) {
      console.log('AudioEncoderDecoder Microphone Open Callback', data)

      const encoderConfig = {
        // eslint-disable-next-line @typescript-eslint/camelcase
        sampling_rate: data.samplingRate,
        // eslint-disable-next-line @typescript-eslint/camelcase
        num_of_channels: data.numberOfChannels,
        params: {
          application: ENCODER_APPLICATION_SIZE,
          // eslint-disable-next-line @typescript-eslint/camelcase
          sampling_rate: ENCODER_SAMPLING_RATE_HRZ,
          // eslint-disable-next-line @typescript-eslint/camelcase
          frame_duration: ENCODER_FRAME_DURATION
        }
      }

      console.log('Goint to set up encoder and decoder')

      encoder.setup(encoderConfig).then(function (packets) {
        decoder.setup({}, packets).then(function (info) {
          _this.player.init(info.sampling_rate, info.num_of_channels).then(function () {
            _this.player.start()
            _this.bufferStatus = window.setInterval(function () {
              console.log(_this.player.getBufferStatus())
            }, 1000)
          }, _this.outputErrorLogs('player.init error'))
        }, _this.outputErrorLogs('decoder.setup error'))
      }, _this.outputErrorLogs('encoder.setup error'))
    }, this.outputErrorLogs('open error'))

    this.player.handleNeedBuffer = function () {
      // console.log('handleNeedBuffer')

      if (_this.microphoneAudioReader && !(_this.microphoneAudioReader.isBeingUsed || working)) {
        working = true
        if (packetQueue.length > 0) {
          const packets = packetQueue.shift()

          // Send an event with the latest data to the recordingPart store
          if (packets) {
            recordingPart.updateRecording(packets.data)
          }

          if (_this.player) {
            // defo need to check if it's always 1920 samples on other people's machines
            // decoder.decode(packets[0]).then(function (buf) {
            const samples = new Float32Array(1920)
            samples.fill(-0.0001)
            const decodedbuffer = {
              status: 0,
              timestamp: 0,
              samples,
              transferable: true
            }
            _this.player.enqueue(decodedbuffer).catch(_this.outputErrorLogs('ringbuf enqueue error?'))
          }

          working = false
        } else {
          _this.microphoneAudioReader.read().then(function (buf) {
            encoder.encode(buf).then(function (packets) {
              if (packets.length === 0) {
                working = false
              } else {
                for (let indexPacket = 1; indexPacket < packets.length; ++indexPacket) {
                  packetQueue.push(packets[indexPacket])
                }
                // Send an event with the latest data to the recordingPart store
                recordingPart.updateRecording(packets[0].data)

                if (_this.player) {
                  // defo need to check if it's always 1920 samples on other people's machines
                  // decoder.decode(packets[0]).then(function (buf) {
                  const samples = new Float32Array(1920)
                  samples.fill(-0.0001)
                  const decodedbuffer = {
                    status: 0,
                    timestamp: 0,
                    samples,
                    transferable: true
                  }
                  _this.player.enqueue(decodedbuffer).catch(_this.outputErrorLogs('ringbuf enqueue error?'))
                }

                working = false
              }
            }, _this.outputErrorLogs('encoder.encode error'))
          }, _this.outputErrorLogs('microphoneAudioReader.read error'))
        }
      }
    }
  }

  AudioEncoderDecoder.prototype.outputErrorLogs = function (prefix) {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const _this = this
    return function (e) {
      _this.player.close()
      _this.player = null

      console.log(prefix, e)
    }
  }

  AudioEncoderDecoder.prototype.stop = function () {
    console.log('AudioEncoderDecoder STOP')

    if (this.microphoneAudioReader) {
      this.microphoneAudioReader.close()
      this.microphoneAudioReader = null
    }

    if (this.player) {
      console.log('AudioEncoderDecoder player getting closed and destroyed')
      this.player.close()
      this.player = null
    }

    clearInterval(this.bufferStatus)
    // should also close audio context and mic etc.
  }

  return AudioEncoderDecoder
})()

export const audioRecorder = new AudioEncoderDecoder()

export default AudioEncoderDecoder
