



































import Component, { mixins } from 'vue-class-component'

import { Vue } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Transcripts from '@/store/modules/transcripts'
import RecordingPart from '@/store/modules/recordingPart'
import Translations from '@/store/modules/translations'

import Store from '@/store/index'

const transcripts = getModule(Transcripts)
const recordingPart = getModule(RecordingPart, Store)
const translations = getModule(Translations) // Accesssor for translations state.

const REDO_KEY = 'recordingsRedoRecordingText'
const NEXT_KEY = 'recordingsNextRecordingText'
const FINISH_KEY = 'recordingsFinishText'

const PlayCurrentRecordingProps = Vue.extend({
  props: {
    isSendingRecordingPart: Boolean
  }
})

@Component
export default class PlayCurrentRecording extends mixins(PlayCurrentRecordingProps, Vue) {
  /**
   * This component allows the User to play back the recording that they have just made.
   * It shows two buttons:
   * - Redo Recording - this sets isCountingDown = true which is the beginning of the recording process (wihtout saving the recording).
   * - Next Recording - this sets isCountingDown = true which is the beginning of the recording process,
   * it also adds currentRecording to recordingList (which saves a recording to the DB),
   * and finally it increments numberOfRecordedWakewords by 1 (because the User has chosen to save this recording)
   * Note: if the User is on the final recording the buttons change to:
   * - Redo Recording - same as before.
   * - Continue - this adds currentRecording to recordingList (which saves a recording to the DB),
   * and increments numberOfRecordedWakewords by 1 (because the User has chosen to save this recording),
   * (it does not trigger a new recording because it is the final one).
   */
  redoText = translations.getTranslations(REDO_KEY)
  nextText = translations.getTranslations(NEXT_KEY)
  recordingsFinishText = translations.getTranslations(FINISH_KEY)

  get areAllTranscriptionsDone (): boolean {
    return recordingPart.getIndex >= transcripts.getTranscriptionsCount
  }
}
