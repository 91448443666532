import { AUDIO_CONSTRAINTS } from '@/constants'

export default {
  async checkMicrophonePermissionAvailable (): Promise<boolean> {
    let isAvailable = false

    try {
      // If we can't aquire a MediaStream, it either means the microphone hasn't got permission or something's blocking
      // aquiring the microphone audio input
      await navigator.mediaDevices.getUserMedia(AUDIO_CONSTRAINTS)

      isAvailable = true
    } catch (mediaStreamError) {
      console.error(mediaStreamError)
    }

    return isAvailable
  }
}
