

























import Recording from '@/store/modules/recording'
import { Vue, Component } from 'vue-property-decorator'
import { getModule } from 'vuex-module-decorators'
import Translations from '@/store/modules/translations'

import { RECORDING_STATE } from '@/constants'

import Store from '@/store/index'

const translations = getModule(Translations) // Accesssor for translations state.
const recording = getModule(Recording, Store) // Accessor for recording state.

// Keys
const KEY_GET_READY = 'recordingsGetReadyText'
const KEY_SILENT = 'recordingsStayQuietText'

// Define the props by using Vue's canonical way.
const CountdownProps = Vue.extend({
  props: {
    length: Number
  }
})

@Component
export default class CountdownHelper extends CountdownProps {
  getReadyText = translations.getTranslations(KEY_GET_READY)
  silentText = translations.getTranslations(KEY_SILENT)
  /**
   * This component provides a green single digit countdown.
   * props - the lenght of the countdown.
   * This component is used by RecordWakewords.vue
   */
  handleCountdownFinished () {
    console.log('handleCountdownFinished')

    recording.updateStatus(RECORDING_STATE.RECORDING)
  }
}
